<template>
  <div>
    <PreparingResearches></PreparingResearches>
  </div>
</template>

<script>
import PreparingResearches from './../../../components/preparingresearches/PreparingResearches.vue';
export default {
  name: 'App',
  components: {
    PreparingResearches,
  }
}
</script>

<style lang="scss">
  @import "./../../assets/sass/core/ui.scss";
</style>
