import {defineStore} from "pinia";
import {setChosenFlag} from "@/helpers/setChosenFlag";
import {useUserStore} from "@/stores/UserStore";


const API_url = "https://lahtaclinic.ru/app/cache/tests.json";
export const useRenderStore = defineStore('renderStore', {
    state() {
        return {
            step: 1,
            stepsMax: 4,
            researches: [
                {
                  id: 1,
                  title: "Колоноскопия",
                  description: "Современный инструментальный метод исследования слизистой оболочки кишечника",
                  innerName: "colonoscopy",
                  isChosen: false,
                },
            ],
            times: [
                // morning
                {
                    time: "08:00",
                    dayPart: "morning",
                    innerTime: "8:00",
                    isChosen: false,
                    
                },
                {
                    time: "08:30",
                    dayPart: "morning",
                    innerTime: "8:30",
                    isChosen: false,
                    
                },
                {
                    time: "09:00",
                    dayPart: "morning",
                    innerTime: "9:00",
                    isChosen: false,
                    
                },
                {
                    time: "09:30",
                    dayPart: "morning",
                    innerTime: "9:30",
                    isChosen: false,
                    
                },
                {
                    time: "10:00",
                    dayPart: "morning",
                    innerTime: "10:00",
                    isChosen: false,
                    
                },
                {
                    time: "10:30",
                    dayPart: "morning",
                    innerTime: "10:30",
                    isChosen: false,
                    
                },
                {
                    time: "11:00",
                    dayPart: "morning",
                    innerTime: "11:00",
                    isChosen: false,
                    
                },
                {
                    time: "11:30",
                    dayPart: "morning",
                    innerTime: "11:30",
                    isChosen: false,
                    
                },
                // day
                {
                    time: "12:00",
                    dayPart: "day",
                    innerTime: "12:00",
                    isChosen: false,
                    
                },
                {
                    time: "12:30",
                    dayPart: "day",
                    innerTime: "12:30",
                    isChosen: false,
                    
                },
                {
                    time: "13:00",
                    dayPart: "day",
                    innerTime: "13:00",
                    isChosen: false,
                    
                },
                {
                    time: "13:30",
                    dayPart: "day",
                    innerTime: "13:30",
                    isChosen: false,
                    
                },
                {
                    time: "14:00",
                    dayPart: "day",
                    innerTime: "14:00",
                    isChosen: false,
                    
                },
                {
                    time: "14:30",
                    dayPart: "day",
                    innerTime: "14:30",
                    isChosen: false,
                    
                },
                {
                    time: "15:00",
                    dayPart: "day",
                    innerTime: "15:00",
                    isChosen: false,
                    
                },
                {
                    time: "15:30",
                    dayPart: "day",
                    innerTime: "15:30",
                    isChosen: false,
                    
                },
                // evening
                {
                    time: "16:00",
                    dayPart: "evening",
                    innerTime: "16:00",
                    isChosen: false,
                    
                },
                {
                    time: "16:30",
                    dayPart: "evening",
                    innerTime: "16:30",
                    isChosen: false,
                    
                },
                {
                    time: "17:00",
                    dayPart: "evening",
                    innerTime: "17:00",
                    isChosen: false,
                    
                },
                {
                    time: "17:30",
                    dayPart: "evening",
                    innerTime: "17:30",
                    isChosen: false,
                    
                },
                {
                    time: "18:00",
                    dayPart: "evening",
                    innerTime: "18:00",
                    isChosen: false,
                    
                },
                {
                    time: "18:30",
                    dayPart: "evening",
                    innerTime: "18:30",
                    isChosen: false,
                    
                },
                {
                    time: "19:00",
                    dayPart: "evening",
                    innerTime: "19:00",
                    isChosen: false,
                    
                },
            ],
            medications: [],
        };
    },
    getters: {
        timesToRender() {
            const timesDefaultStructure = {
                morning: {
                    dayPart: "Утро",
                    times: [],
                },
                day: {
                    dayPart: "День",
                    times: [],
                },
                evening: {
                    dayPart: "Вечер",
                    times: [],
                },
            };

            for (let i = 0; i < this.times.length; i++) {
                let time = this.times[i];
                let timeDayPart = time.dayPart;

                timesDefaultStructure[timeDayPart].times.push(time);
            }

            const timesToRender = Object.values(timesDefaultStructure);
            return timesToRender;
        },
        medicationsForResearch() {
            let research = this.researches.find(research => research.isChosen);
            if (!research) return [];

            const medicationsForResearch = this.medications.filter(medication => medication.info.research === research.innerName);
            return medicationsForResearch;
        }
    },
    actions: {
        nextStep() {
            const userStore = useUserStore();
            let nextStep = this.step + 1;

            let isNotValid = (nextStep > this.stepsMax) ||
                (!this.areNotResearchesChosen() && (this.step === 1)) ||
                ((this.step === 2) && (userStore.filteredDate === null || userStore.time === null)) ||
                ((this.step === 3) && (userStore.medication === null));
            if (isNotValid) return;

            this.step = nextStep;

            this.goToLowPoint();
            if (this.step === 4) {
                this.freezeBody();
            }
        },
        prevStep() {
            let prevStep = this.step - 1;
            if (prevStep === 0) return;

            this.step = prevStep;

            if (this.step !== 4) this.unfreezeBody();
        },
        chooseItemInCollection(id, collection) {
            for (let i = 0; i < collection.length; i++) {
                collection[i].isChosen = (collection[i].id === id);
            }
        },
        areNotResearchesChosen() {
            for (let i = 0; i < this.researches.length; i++) {
                if (this.researches[i].isChosen) return true;
            }

            return false;
        },
        async getMedications() {
            let result = await fetch(API_url).then(response => response.json());
            setChosenFlag(result);
            this.medications = result;
        },
        freezeBody() {
            let freezeClass = "freeze-body";
            document.body.classList.add(freezeClass);
        },
        unfreezeBody() {
            let freezeClass = "freeze-body";
            document.body.classList.remove(freezeClass);
        },
        goToLowPoint() {
            const lowPoint = document.querySelector(".preparing-researches__app-low-point");
            lowPoint.scrollIntoView({
                behavior: "auto",
            });
        }
    },
});