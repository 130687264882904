<template>
  <div class="step-two">
    <p class="step-two__subtext">Выберите дату и время исследований:</p>
    <div class="step-two__blocks-to-choose">
      <!-- date -->
      <div class="step-two__date">
        <Datepicker class="step-two-datepicker"
                    locale="ru"
                    month-name-format="long"
                    :month-change-on-scroll="false"
                    v-model="userStore.date"
                    inline
                    :hideNavigation="['time']"
                    autoApply
                    :disabled="false"

        ></Datepicker>
      </div>
      <!-- time -->
      <div class="step-two__time">
        <div class="step-two-time-part"
          v-for="(timePart, timePartIndex) in renderStore.timesToRender"
          :key="timePartIndex">
          <div class="step-two-time-part__day-part-display">{{ timePart.dayPart }}</div>
          <div class="step-two-time-part__times-collection">
            <div class="step-two-time"
                 v-for="(timeUnit) in timePart.times"
                 :key="timeUnit.time"
                 :class="{
                   'step-two-time_chosen': timeUnit.isChosen,
                   'step-two-time_disabled': timeUnit.isDisabled,
                 }"
                 @click="userStore.chooseTime(timeUnit)">
              {{ timeUnit.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import {useUserStore} from "../../../apps/preparingresearches/src/stores/UserStore";
import {useRenderStore} from "../../../apps/preparingresearches/src/stores/RenderStore";
export default {
  name: "Step2View",
  components: {
    Datepicker,
  },
  setup() {
    const userStore = useUserStore();
    const renderStore = useRenderStore();

    return {
      userStore,
      renderStore,
    };
  },
  computed: {

  },
  methods: {
    setDatePickerClasses() {
      //
      let leftArrowClass = "left-arrow-block";
      let rightArrowClass = "right-arrow-block";

      let arrowsBlocks = document.querySelectorAll(".dp__month_year_col_nav");

      arrowsBlocks[0].classList.add(leftArrowClass);
      arrowsBlocks[1].classList.add(rightArrowClass);

      //
      let displayMonth = "display-month";
      let displayYear = "display-year";

      let displayParts = document.querySelectorAll(".dp__month_year_wrap .dp__month_year_select");

      displayParts[0].classList.add(displayMonth);
      displayParts[1].classList.add(displayYear);
    },
    // disable old dates
    disableOldDates() {
      const arrowLeft = document.querySelector(".step-two-datepicker .left-arrow-block");
      const arrowRight = document.querySelector(".step-two-datepicker .right-arrow-block");

      this.disableOldDatesEvent();

      arrowLeft.addEventListener("click", this.disableOldDatesEvent);
      arrowRight.addEventListener("click", this.disableOldDatesEvent);
    },
    disableOldDatesEvent() {
      const dayItemsCollection = document.querySelectorAll(".step-two-datepicker .dp__calendar_item");
      const currentDate = new Date();

      for (let i = 0; i < dayItemsCollection.length; i++) {
        let dayContainer = dayItemsCollection[i];

        let dayDateProp = dayContainer.dataset.test;
        const dayDate = new Date(dayDateProp);

        let isPast = this.isFirstDateLess(dayDate, currentDate);

        if (!isPast) return;

        let disableDayModificator = "disable-to-choose";
        dayContainer.classList.add(disableDayModificator);
      }
    },
    isFirstDateLess(firstDate, secondDate) {
      let firstDateToCompare = this.convertDateForCompare(firstDate);
      let secondDateToCompare = this.convertDateForCompare(secondDate);
      let isLess = firstDateToCompare < secondDateToCompare;

      return isLess;
    },
    convertDateForCompare(date) {
      let dateString = date.toLocaleDateString();
      let [day, month, year] = dateString.split(".");
      let numToCompare = +`${year}${month}${day}`;

      return numToCompare;
    },
  },
  mounted() {
    setTimeout(this.setDatePickerClasses, 0);
    setTimeout(this.disableOldDates, 100);
  },
}
</script>

<style lang="scss">
.lahta-ui {
  .step-two {
    &__subtext {
      padding: 0;
      margin: 0;
      border: none;
      font-family: "Lato";
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #545A66;
      margin-bottom: 24px;
      @media (min-width: 1100px) {
        font-size: 21px;
      }
    }
    &__blocks-to-choose {
      @media (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    &__date {
      margin-bottom: 30px;
      @media (min-width: 1000px) {
        margin-right: 40px;
      }
      @media (min-width: 1100px) {
        margin-right: 50px;
      }
      @media (min-width: 1350px) {
        margin-right: 80px;
      }
    }
    &__time {}

    // календарь
    //  контейнер календаря
    .step-two-datepicker .dp__instance_calendar {
      border: 1px solid #E3E9FC;
      border-radius: 10px;
      max-width: 300px;
      @media (min-width: 1100px) {
        width: 400px;
        max-width: 1000px;
      }
    }

    //  дисплей: стрелочки и месяц с годом
    .step-two-datepicker .dp__month_year_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 18px 16px;
    }
    // левая стрелочка
    .step-two-datepicker .left-arrow-block {
      flex-grow: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      @media (min-width: 1100px) {
        cursor: pointer;
      }

      & svg {
        width: 20px;
        height: 20px;
      }
    }
    // дисплей
    .step-two-datepicker .dp__month_year_wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .step-two-datepicker .display-month {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      color: #545A66;
      margin-right: 3px;
    }
    .step-two-datepicker .dp__overlay {
      display: none;
    }
    .step-two-datepicker .display-year {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      color: #545A66;
      margin-left: 3px;

    }
    // правая стрелочка
    .step-two-datepicker .right-arrow-block {
      flex-grow: 0;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      @media (min-width: 1100px) {
        cursor: pointer;
      }

      & svg {
        width: 20px;
        height: 20px;
      }
    }

    // сам календарь
    // основной блок
    .step-two-datepicker .dp__calendar_wrap {
      padding: 0 5px 5px;
    }

    // шапка - ряд с днями недели
    .step-two-datepicker .dp__calendar_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
    }
    // день недели
    .step-two-datepicker .dp__calendar_header_item {
      width: calc(100% / 7);
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      font-family: "Lato";
      font-size: 13px;
      line-height: 140%;
      font-weight: 400;
      color: #6B707A;
      opacity: 0.4;
      @media (min-width: 370px) {
        font-size: 14px;
        height: 32px;
      }
      @media (min-width: 1100px) {
        font-size: 16px;
        height: 44px;
      }
    }

    // контейнер с числами
    .step-two-datepicker .dp__calendar {

    }
    // ряд с числами на неделю
    .step-two-datepicker .dp__calendar_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
    }

    // число, оно же день - контейнер
    .step-two-datepicker .dp__calendar_item {
      width: calc(100% / 7);
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    // число, оно же день - блок
    .step-two-datepicker .dp__cell_inner {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: "Lato";
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #545A66;

      @media (min-width: 370px) {
        font-size: 14px;
        width: 32px;
        height: 32px;
      }
      @media (min-width: 1100px) {
        font-size: 16px;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
    // число, оно же день - выбранный блок
    .step-two-datepicker .dp__calendar_item .dp__active_date:not(.dp__cell_inner_disabled) {
      background: linear-gradient(90deg, rgba(47,126,235,1) 0%, rgba(24,190,231,1) 82%);
      color: #fff;
    }
    // прошлый день, нельзя выбрать
    .step-two-datepicker .disable-to-choose .dp__cell_inner {
      color: #8e98a1;
    }
    .step-two-datepicker .disable-to-choose .dp__active_date {
      background: transparent !important;
      color: #8e98a1 !important;
    }


    // время
    .step-two-time-part {
      margin-bottom: 24px;
      @media (min-width: 1100px) {
        margin-bottom: 16px;
      }
      &__day-part-display {
        font-family: "Lato";
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        color: #545A66;
        margin-bottom: 8px;
      }
      &__times-collection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    .step-two-time {
      width: 68px;
      height: 48px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #E3E9FC;
      border-radius: 8px;
      font-family: "Lato";
      font-size: 16px;
      font-weight: 300;
      line-height: 140%;
      color: #545A66;
      // color to disable
      //#545a666e
      @media (min-width: 1100px) {
        cursor: pointer;
      }
    }
    .step-two-time_chosen {
      border: none;
      background: linear-gradient(90deg, rgba(47,126,235,1) 0%, rgba(24,190,231,1) 82%);
      color: #fff;
    }
  }
}

</style>