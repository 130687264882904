<template>
  <div class="preparing-researches">
    <div class="preparing-researches__content">
      <!-- верхняя часть -->
      <div class="preparing-researches__high-display">
        <h2 class="preparing-researches__title">Калькулятор подготовки к исследованиям</h2>
        <div class="preparing-researches-load-line">
          <div class="preparing-researches-load-line__dynamic-line" :style="loadLineWidthStyle"></div>
          <div class="preparing-researches-load-line__static-line"></div>
        </div>
      </div>
      <!-- средняя часть -->
      <div class="preparing-researches__step-visual">
        <!-- Ядро приложения -->
        <div class="preparing-researches__step-visual-part"
          v-show="renderStore.step === 1">
          <Step1View></Step1View>
        </div>
        <div class="preparing-researches__step-visual-part"
          v-show="renderStore.step === 2">
          <Step2View></Step2View>
        </div>
        <div class="preparing-researches__step-visual-part"
          v-show="renderStore.step >= 3">
          <Step3View></Step3View>
        </div>
        <!-- точка для скролла  -->
        <div class="preparing-researches__app-low-point" id="app_low_point"></div>
      </div>
      <!-- нижняя часть -->
      <div class="preparing-researches__step-btns preparing-researches-step-btns">
        <div class="preparing-researches-step-btns__prev"
                type="button"
                v-show="renderStore.step > 1"
                @click="renderStore.prevStep">
          <span class="preparing-researches-step-btns__prev-text">Назад</span>
          <div class="preparing-researches-step-btns__prev-icon">
            <div class="preparing-researches-step-btns__prev-icon-wrapper" v-html="prevBtnIcon"></div>
          </div>
        </div>
        <div class="preparing-researches-step-btns__next"
                :class="{ 'preparing-researches-step-btns__next_disabled' : isNextBtnDisabled }"
                type="button"
                @click="renderStore.nextStep">
          {{ btnNextText }}
        </div>
      </div>
    </div>
    <!-- попап на шаге 4   -->
    <div class="preparing-researches__step-four-wrapper" v-show="renderStore.step === 4">
      <Step4Popup></Step4Popup>
    </div>
  </div>
</template>

<script>
import {useRenderStore} from "../../apps/preparingresearches/src/stores/RenderStore";
import Step1View from "./views/Step1View.vue";
import Step2View from "./views/Step2View.vue";
import Step3View from "./views/Step3View.vue";
import Step4Popup from "./views/Step4Popup.vue";
import {useUserStore} from "../../apps/preparingresearches/src/stores/UserStore";



export default {
  name: "PreparingResearches",
  components: {
    Step1View,
    Step2View,
    Step3View,
    Step4Popup,
  },
  setup() {
    const renderStore = useRenderStore();
    const userStore = useUserStore();

    return {
      renderStore,
      userStore,
    }
  },
  data() {
    return {
      prevBtnIcon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.74707 12.3789L8.06313 11.6494L3.70432 7.00001L8.06313 2.35062L8.74707 1.62109L7.288 0.253204L6.60406 0.98274L1.60406 6.31607L0.96286 7.00001L1.60406 7.68396L6.60405 13.0173L7.288 13.7468L8.74707 12.3789Z" fill="#545A66"/>
        </svg>
      `,
    };
  },
  computed: {
    loadLineWidthStyle() {
      let step = this.renderStore.step;
      let stepsMax = this.renderStore.stepsMax;
      let widthValue = (step / stepsMax) * 100;

      let style = {
        'width': `${widthValue}%`,
      };

      return style;
    },
    btnNextText() {
      let step = this.renderStore.step;
      let bigTextMin = 3;
      let text = step < bigTextMin ? "Далее" : "Получить инструкцию";

      return text;
    },
    isNextBtnDisabled() {
      let step = this.renderStore.step;
      let areNotResearchesChosen = !this.renderStore.areNotResearchesChosen();

      let isNotValid = (areNotResearchesChosen && (step === 1)) ||
          ((step === 2) && (this.userStore.filteredDate === null || this.userStore.time === null)) ||
          ((step === 3) && (this.userStore.medication === null));

      return isNotValid;
    }
  },
  async created() {
    await this.renderStore.getMedications();
  },
}
</script>

<style lang="scss">
.freeze-body {
  overflow: hidden !important;
}

.lahta-ui {
  .preparing-researches {
    background-color: transparent;
    padding: 0 16px 100px;
    @media (min-width: 800px) {
      padding: 0 45px 120px;
    }
    @media (min-width: 1100px) {
      padding: 0 90px 140px;
    }
    @media (min-width: 1600px) {
      padding: 0 140px 140px;
    }
    @media (min-width: 1900px) {
      padding: 0 160px 140px;
    }
    @media (min-width: 2300px) {
      padding: 0 320px 140px;
    }
    @media (min-width: 2600px) {
      padding: 0 360px 140px;
    }
    @media (min-width: 3100px) {
      padding: 0 400px 140px;
    }

    &__content {
      background-color: #fff;
      min-height: 550px;
      border-radius: 16px;
      padding: 20px 24px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      @media (min-width: 1100px) {
        min-height: 800px;
        padding: 64px 80px;
      }
      @media (min-width: 2100px) {
        padding: 74px 100px;
      }
      @media (min-width: 2400px) {
        padding: 74px 120px;
      }
      @media (min-width: 2600px) {
        padding: 74px 140px;
      }
      @media (min-width: 2800px) {
        padding: 74px 160px;
      }

    }
    &__high-display {
      flex-grow: 0;
      margin-bottom: 24px;
      @media (min-width: 1100px) {
        margin-bottom: 48px;
      }
    }
    &__title {
      padding: 0;
      margin: 0;
      font-family: "Lato";
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 120% !important;
      color: #8193B2 !important;
      margin-bottom: 16px !important;
      @media (min-width: 1100px) {
        font-weight: 300 !important;
        font-size: 40px !important;
        margin-bottom: 32px !important;
      }
    }
    &__step-visual {
      flex-grow: 1;
      flex-shrink: 0;
      margin-bottom: 40px;
      position: relative;
      @media (min-width: 1100px) {
        margin-bottom: 48px;
      }
    }
    &__step-visual-part {
      width: 100%;
      height: 100%;
    }
    &__step-btns {
      flex-grow: 0;
    }
    &__app-low-point {
      position: absolute;
      width: 1px;
      height: 1px;
      top: -28px;
      left: 0;
      background: transparent;
    }
    &__step-four-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }

  .preparing-researches-load-line {
    width: 100%;
    height: 3px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    background-color: #F1F3F9;
    &__dynamic-line {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      background: linear-gradient(90deg, rgba(92,88,242,1) 0%, rgba(47,197,238,1) 53%);
      transition: width 0.2s ease;
    }
  }

  .preparing-researches-step-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__prev {
      display: inline-block;
      margin-right: 30px;
      &-text {
        display: none;
        @media (min-width: 800px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 56px;
          font-family: "Lato";
          font-weight: 600;
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          text-transform: uppercase;
          border-radius: 28px;
          border: 1px solid #E3E9FC;
          color: #545A66;
          letter-spacing: 0.05em;
          box-shadow: 1px 2px 7px 0px rgba(255, 255, 255, 0.2);
          transition: box-shadow 0.2s ease;
          @media (min-width: 1100px) {
            cursor: pointer;
          }
          &:hover {
            @media (min-width: 1100px) {
              box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
            }
          }
        }
      }
      &-icon {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #E3E9FC;
        @media (min-width: 800px) {
          display: none;
        }
        &-wrapper {
          display: inline-block;
          position: relative;
          top: 1px;
          left: -1px;
        }
      }
    }
    &__next {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      text-align: center;
      flex-grow: 1;
      height: 56px;
      border-radius: 28px;
      font-family: "Lato";
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #fff;
      text-transform: uppercase;
      background: linear-gradient(90deg, rgba(47,126,235,1) 0%, rgba(1,238,200,1) 53%);
      box-shadow: 1px 2px 7px 0px rgba(255, 255, 255, 0.2);
      transition: box-shadow 0.2s ease;
      @media (min-width: 550px) {
        flex-grow: 0;
      }
      @media (min-width: 800px) {
        font-size: 14px;
      }
      @media (min-width: 950px) {
        width: 310px;
      }
      @media (min-width: 1100px) {
        cursor: pointer;
      }

      &:hover {
        @media (min-width: 1100px) {
          box-shadow: 3px 1px 8px 0px rgba(34, 60, 80, 0.2);
        }
      }
      &_disabled {
        opacity: 0.2;
      }
    }
  }
}
</style>