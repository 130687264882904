<template>
  <div class="step-four" @click="closePopupFromBackground">
    <div class="step-four__content">
      <div class="step-four__close-btn" v-html="closeIcon" @click="renderStore.prevStep"></div>
      <img class="step-four__pic" src="../../../apps/preparingresearches/src/assets/step-4_big-icon.svg">
      <h2 class="step-four__title">Рекомендации по подготовке к эндоскопическому исследованию готовы</h2>
<!--      <p class="step-four__description">Если пациент записывается на исследование за один-два дня, не получится полностью соблюдать рекомендованную трехдневную диету с низким содержанием клетчатки</p>-->
      <div class="step-four__open-btn" @click="userStore.openFile">
        <div class="step-four__open-btn-content">
          <div class="step-four__open-btn-icon" v-html="downloadIcon"></div>
          <div class="step-four__open-btn-text">Открыть инструкцию</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRenderStore} from "../../../apps/preparingresearches/src/stores/RenderStore";
import {useUserStore} from "../../../apps/preparingresearches/src/stores/UserStore";

export default {
  name: "Step4Popup",
  setup() {
    const renderStore = useRenderStore();
    const userStore = useUserStore();

    return {
      renderStore,
      userStore,
    };
  },
  data() {
    return {
      downloadIcon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="white"/>
        </svg>
      `,
      closeIcon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M20 4L3.99978 20.0002" stroke="#8093B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4 4L20.0002 20.0002" stroke="#8093B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
    };
  },
  methods: {
    closePopupFromBackground(event) {
      let inValidBlock = event.target.closest(".step-four__content");
      if (inValidBlock !== null) return;

      this.renderStore.prevStep();
    },
  },
}
</script>

<style lang="scss">
.lahta-ui {
  .step-four {
    width: 100%;
    height: 100%;
    background-color: rgba(77, 73, 73, 0.19);
    padding: 120px 9px 0;
    @media (min-width: 570px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    @media (max-width: 1099px) and (max-height: 820px) {
      padding-top: 48px;
      padding-bottom: 5px;
      overflow: auto;
    }
    @media (min-width: 1100px) and (max-height: 770px) {
      padding-top: 80px;
      padding-bottom: 5px;
      overflow: auto;
    }
    &__content {
      background-color: white;
      border-radius: 16px;
      padding: 60px 16px 80px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      @media (min-width: 570px) {
        width: 552px;
      }
      @media (min-width: 1100px) {
        width: 630px;
        padding: 60px 40px 80px;
      }
    }
    &__close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (min-width: 1100px) {
        cursor: pointer;
      }
    }
    &__pic {
      display: block;
      width: 156px;
      margin-bottom: 40px;
    }
    &__title {
      padding: 0;
      margin: 0;
      border: none;
      font-family: "Lato";
      font-size: 22px;
      line-height: 120%;
      font-weight: 300;
      color: #8193B2;
      text-align: center;
      margin-bottom: 24px;
      @media (min-width: 1100px) {
        font-size: 32px;
        margin-bottom: 40px;
      }
    }
    &__description {
      padding: 0;
      margin: 0;
      border: none;
      font-family: "Lato";
      font-size: 16px;
      line-height: 140%;
      font-weight: 400;
      color: #8193B2;
      text-align: center;
      margin-bottom: 40px;
      @media (min-width: 1100px) {
        font-size: 18px;
        margin-bottom: 64px;
      }
    }
    &__open-btn {
      width: 265px;
      height: 56px;
      border-radius: 28px;
      background: linear-gradient(90deg, rgba(47,126,235,1) 0%, rgba(1,238,200,1) 53%);
      box-shadow: 1px 2px 7px 0px rgba(255, 255, 255, 0.2);
      transition: box-shadow 0.2s ease;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (min-width: 1100px) {
        cursor: pointer;
      }
    }
    &__open-btn-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__open-btn-icon {
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
    }
    &__open-btn-text {
      font-family: "Lato";
      font-weight: 600;
      font-size: 13px;
      line-height: 120%;
      color: #fff;
      text-transform: uppercase;
      margin-left: 4px;
    }
  }
}
</style>