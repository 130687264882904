import {defineStore} from "pinia";
import {useRenderStore} from "@/stores/RenderStore";


export const useUserStore = defineStore('userStore', {
    state() {
        return {
            researchName: "",
            date: null,
            time: null,
            medication: null,
        };
    },
    getters: {
        showDate() {
            console.log(this.date);
        },
        filteredDate() {
            const chosenDate = this.date;
            const currentDate = new Date();
            const dateToShow = !this.isFirstDateLess(chosenDate, currentDate) ? chosenDate : null;

            return dateToShow;
        },
    },
    actions: {
        isFirstDateLess(firstDate, secondDate) {
            let firstDateToCompare = this.convertDateForCompare(firstDate);
            let secondDateToCompare = this.convertDateForCompare(secondDate);
            let isLess = firstDateToCompare < secondDateToCompare;

            return isLess;
        },
        convertDateForCompare(date) {
            if (!date) return 0;

            let dateString = date.toLocaleDateString();
            let [day, month, year] = dateString.split(".");
            let numToCompare = +`${year}${month}${day}`;

            return numToCompare;
        },
        chooseResearch(research) {
            const renderStore = useRenderStore();
            renderStore.chooseItemInCollection(research.id, renderStore.researches);
            this.researchName = research.innerName;
        },
        chooseTime(timeToChoose) {
            // if (timeToChoose.isDisabled) return;

            const renderStore = useRenderStore();

            for (let i = 0; i < renderStore.times.length; i++) {
                let localTime = renderStore.times[i];
                localTime.isChosen = (localTime === timeToChoose);
            }

            this.time = timeToChoose;
        },
        chooseMedication(medicationToChoose) {
            const renderStore = useRenderStore();

            for (let i = 0; i < renderStore.medications.length; i++) {
                let localMedication = renderStore.medications[i];
                localMedication.isChosen = (localMedication === medicationToChoose);
            }

            this.medication = medicationToChoose;
        },
        openFile() {
            const chosenTime = this.time;
            const medication = this.medication;

            let timeValue = chosenTime.time;
            let chosenFile = medication.info.list_pdf.find(file => file.time === timeValue);
            let filePath = chosenFile.pdf;

            window.open(filePath, '_blank');
        },
    },
});