<template>
  <div class="step-three">
    <p class="step-three__subtext">Выберите препарат:</p>
    <div class="step-three__medications">
      <div class="step-three-medication"
           v-for="medication in renderStore.medicationsForResearch"
           :key="medication.id"
           :class="{ 'step-three-medication_chosen': medication.isChosen }"
           @click="userStore.chooseMedication(medication)">
        <div class="step-three-medication__gradient-background"></div>
        <div class="step-three-medication__content-wrapper">
          <div class="step-three-medication__content">
            <!-- icon -->
            <div class="step-three-medication__icon">
              <div class="step-three-medication__icon-gradient-background"></div>
              <div class="step-three-medication__icon-inner-circle"></div>
            </div>
            <!-- text -->
            <div class="step-three-medication__text">
              <p class="step-three-medication__title">{{ medication.info.title }}</p>
              <p class="step-three-medication__description">{{ medication.info.from }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRenderStore} from "../../../apps/preparingresearches/src/stores/RenderStore";
import {useUserStore} from "../../../apps/preparingresearches/src/stores/UserStore";

export default {
  name: "Step3View",
  setup() {
    const renderStore = useRenderStore();
    const userStore = useUserStore();

    return {
      renderStore,
      userStore,
    };
  },
}
</script>

<style lang="scss">
.lahta-ui {
  .step-three {
    width: 100%;
    height: 100%;
    &__subtext {
      padding: 0;
      margin: 0;
      border: none;
      font-family: "Lato";
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      color: #545A66;
      margin-bottom: 24px;
      @media (min-width: 1100px) {
        font-size: 21px;
      }
    }
    &__medications {}
  }

  .step-three-medication {
    position: relative;
    background-color: #E3E9FC;
    border-radius: 8px;
    &:not(&:last-child) {
      margin-bottom: 8px;
    }
    @media (min-width: 1100px) {
      cursor: pointer;
    }

    &__gradient-background {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      opacity: 0;
      transition: opacity 0.2s ease;
      background: linear-gradient(90deg, rgba(92,88,242,1) 0%, rgba(64,156,239,1) 82%, rgba(47,197,238,1) 91%);
    }
    &__content-wrapper {
      position: relative;
      z-index: 2;
      padding: 2px;
      border-radius: 8px;
      width: 100%;
      background-color: #fff;
      background-clip: content-box;
    }
    &__content {
      padding: 12px 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    &__icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: relative;
      margin-right: 24px;
      border: 1px solid #E3E9FC;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &__icon-gradient-background {
      position: absolute;
      z-index: 1;
      top: -1px;
      left: -1px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s ease;
      background: linear-gradient(90deg, rgb(92, 88, 242) 0%, rgb(64, 156, 239) 82%, rgb(47, 197, 238) 91%);
    }
    &__icon-inner-circle {
      position: relative;
      z-index: 2;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      background-color: #fff;
    }
    &__text {
      font-family: "Lato";
    }
    &__title {
      padding: 0;
      margin: 0;
      border: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #545A66;
      margin-bottom: 2px;
      @media (min-width: 1100px) {
        font-size: 18px;
      }
    }
    &__description {
      padding: 0;
      margin: 0;
      border: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: #8093B2;
      @media (min-width: 1100px) {
        font-size: 16px;
      }
    }
  }
  .step-three-medication_chosen .step-three-medication__gradient-background {
    opacity: 1;
  }
  .step-three-medication_chosen .step-three-medication__icon-gradient-background {
    opacity: 1;
  }
}
</style>